@tailwind base;

html {
  font-size: 14px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: always;
  text-rendering: optimizeLegibility;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, Helvetica, sans-serif;
}

.wrapper {
  @apply max-w-screen-xl mx-auto px-8 md:px-10 relative;
}

@tailwind components;
@tailwind utilities;

ul li {
  list-style-type: disc;
  margin-left: 20px;
}

ol li {
  list-style-type: decimal;
  margin-left: 20px;
}

.animate-down {
  animation-name: down;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
}

@keyframes down {
  0% {opacity: 0; top: 10px; position: relative}
  100% {opacity: 1; top: 0; position: relative}
}

.animate-down1 {
  animation-name: down1;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
}

@keyframes down1 {
  0% {opacity: 0; top: 10px; position: relative}
  77% {opacity: 0; top: 10px; position: relative}
  100% {opacity: 1; top: 0; position: relative}
}

.animate-down2 {
  animation-name: down2;
  animation-duration: 1.3s;
  animation-timing-function: ease-in-out;
}

@keyframes down2 {
  0% {opacity: 0; top: 10px; position: relative}
  87% {opacity: 0; top: 10px; position: relative}
  100% {opacity: 1; top: 0; position: relative}
}